import uniqBy from 'lodash/uniqBy'
import apiConfig from 'config/apiConfig'
import { getParam } from 'common/utils'
import { getShipmentsByFilters } from './shipmentsByFilters'
import { BookingTypes } from 'common/types'

export const BOOL_MAP: { [key: string]: any } = {
  Yes: true,
  No: false,
}

export const FIELD_MAP: { [key: string]: any } = {
  acceptDropTrailers: 'accept_drop_trailers',
  defaultCommodity: 'default_commodity',
  isPalletized: 'palletized',
  isPalletsStackable: 'pallets_stackable',
  palletVolume: 'pallet_volume',
  maxOutboundCartonsPerDay: 'max_outbound_cartons',
  maxOutboundTrailersPerDay: 'max_outbound_trailers',
  verifiedByUser: 'verified_by_user',
}

export const getMappedValue = (
  field: string,
  value: string | number | boolean | undefined,
  isNotRequired?: boolean | string,
) => {
  if (
    [
      'isPalletized',
      'isPalletsStackable',
      'acceptDropTrailers',
      'verifiedByUser',
      'acceptRearRollDoorTrailers',
    ].includes(field)
  ) {
    if (value === 'Yes') {
      return true
    }
    if (value === 'No') {
      return false
    }
  }
  if (field === 'defaultCommodity' && value === 'Please Select a Commodity') {
    return null
  }

  if (
    (field === 'maxOutboundCartonsPerDay' ||
      field === 'maxOutboundTrailersPerDay') &&
    !value
  ) {
    return 0
  }

  if (
    ['dropTrailerRejectionReason', 'rollDoorRejectionReason'].includes(field)
  ) {
    if (
      value === undefined ||
      isNotRequired === true ||
      isNotRequired === 'Yes'
    ) {
      return 'N/A'
    }
  }

  return value
}

export const mapRequestVariables = (shipments: any) => {
  return uniqBy(shipments, 'purchaseOrderNumber').map((shipment: any) => {
    const { vendorId, purchaseOrderNumber, department } = shipment
    return {
      department,
      purchaseOrderNumber,
      vendorId,
    }
  })
}

export const createMappedShipmentsRequest = (
  mappedRequestVariables: any,
  filters: any,
  bookingTypes: BookingTypes,
) => {
  return mappedRequestVariables.map(async (item: any) => {
    const { department, purchaseOrderNumber, vendorId } = item
    const shipments = await getShipmentsByFilters(
      bookingTypes,
      purchaseOrderNumber,
      department,
      vendorId,
      filters.shipmentIds,
      filters.loadIds,
      filters.truckLoadOptimizationRefIds,
      filters.dateFrom,
      filters.dateTo,
    )
    return {
      department,
      purchaseOrderNumber,
      shipments: shipments?.list ?? [],
    }
  })
}

export const getUiServicesEndpoint: () => Promise<{
  url: string
  key: string
}> = async () => {
  const isDebugMode = getParam('debug')
  const isMockVendorMode = getParam('mockVendor')

  return {
    url:
      isDebugMode || isMockVendorMode
        ? 'http://localhost:4000'
        : apiConfig.api.edge,
    key: apiConfig.api.key,
  }
}
