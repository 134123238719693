import { useQueries, useQuery } from 'react-query'
import { logEvent, LogLevel } from '@praxis/component-logging'
import {
  departmentsEndpoint,
  getDepartments,
} from '../../services/getDepartments'
import { fetchLocationCapacity } from 'services/getLocationCapacity'
import {
  fetchDestinationsForPoAndDeptEndpoint,
  transformPurchaseOrderDetails,
} from 'services/fetchDestinationsForPoAndDept'
import { fetchIRDDateEndpoint, transformIRD } from 'services/fetchIRD'
import {
  getLocationsAndProducts,
  transformLocations,
  transformProducts,
} from 'services/getLocationsAndProducts'
import { getShortestTransitTime } from '../../services/shortestTransitTime'
import {
  BookingTypes,
  PurchaseOrder,
  RawShipmentDetails,
  ReverseDestination,
  ShipmentRowInterface,
  TloAlert,
  VendorId,
} from 'common/types'
import {
  fetchPurchaseOrder,
  transformPaginationMetadata,
  transformPurchaseOrders,
} from '../../services/getPurchaseOrders'
import { vendorAttributesEndpoint } from '../../services/vendorAttributes'
import { getDestinationByVendorIdsEndpoint } from '../../services/fetchDestinationsForVendorIds'
import { getShipmentsNeedReviewByVendorTloRefId } from 'services/shipmentsByTloRefId'
import { getShipmentsByFilters } from 'services/shipmentsByFilters'
import { PO_BOOKING_TYPES } from 'common/constants'
import { fetchReverseOriginLocations } from '../../services/fetchReverseOriginLocations'
import { ReverseOriginResponse } from '../../components/FormInputs/ReverseOrigin'
import {
  fetchReverseDestinations,
  ReverseDestinationResponse,
} from '../../services/fetchReverseDestinations'

export const useDepartmentsQuery = (vendorIds?: number[]) => {
  const departmentsQuery = useQuery(
    ['departments', vendorIds?.join()],
    () => getDepartments(vendorIds),
    {
      refetchOnWindowFocus: false, // default: true
    },
  )

  if (departmentsQuery.isError) {
    logEvent(
      {
        message: JSON.stringify({
          endpoint: departmentsEndpoint,
          error: departmentsQuery.error,
          message: 'Failed to fetch departments',
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )
  }
  return departmentsQuery
}

export const useShortestTransitTimeQuery = (
  vendorId: number,
  vop: number,
  department: string | number,
  destLocCode: string,
) => {
  return useQuery(
    [vendorId, vop, department, destLocCode],
    () => getShortestTransitTime(vendorId, vop, department, destLocCode),
    {
      refetchOnWindowFocus: false, // default: true
    },
  )
}

export const useMaxCapacityByVendorLocationPickupDateQuery = (
  locationId: string | undefined,
  vendorId: undefined | number,
  pickupDate: string | undefined,
  hasDiffPickupDate: boolean,
) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: `
      location-capacity-${locationId}-${vendorId || ''}-${pickupDate}`,
    queryFn: () => fetchLocationCapacity(locationId, vendorId, pickupDate),
    refetchOnWindowFocus: false,
    enabled: hasDiffPickupDate && !!locationId && !!pickupDate && !!vendorId,
  })

  return { data, isError, isLoading }
}

export const useSameLoadShipmentsQuery = (
  loadId: number | string,
  bookingTypes: BookingTypes,
) => {
  return useQuery(['useSameLoadShipments', loadId], {
    queryFn: async () => {
      const result = await getShipmentsByFilters(
        bookingTypes,
        undefined,
        undefined,
        undefined,
        undefined,
        [loadId],
      )
      return result?.list
    },
    refetchOnWindowFocus: false,
  })
}

export const useLocationsProductsQuery = (
  vendorList: Array<String>,
  enableUseLocationProducts: boolean,
  onSuccess: ((data: any) => void) | undefined,
  onError: ((err: Error) => void) | undefined,
) => {
  return useQuery(
    ['location-product', vendorList],
    () => getLocationsAndProducts(vendorList),
    {
      onSuccess,
      onError,
      select: (response: any) => {
        return {
          locations: transformLocations(response.data.vendor_locations),
          products: transformProducts(
            response.data.products,
            response.data.vendor_locations,
          ),
        }
      },
      enabled: enableUseLocationProducts,
      refetchOnWindowFocus: false,
    },
  )
}

export const useShipmentByPOKeyQuery = (
  po?: PurchaseOrder,
  department?: string,
  vendorId?: VendorId,
  shipmentIds?: Array<string>,
  loadIds?: Array<string>,
  truckLoadOptimizationRefIds?: Array<string>,
  dateFrom?: string,
  dateTo?: string,
  bookingCategories?: Array<string>,
  destinationLocCode?: Array<string>,
) => {
  return useQuery(
    [
      'shipments-by-po',
      po,
      department,
      vendorId,
      shipmentIds,
      loadIds,
      truckLoadOptimizationRefIds,
      dateFrom,
      dateTo,
      bookingCategories,
      destinationLocCode,
    ],
    () =>
      getShipmentsByFilters(
        PO_BOOKING_TYPES,
        po,
        department,
        vendorId ? [vendorId] : undefined,
        shipmentIds,
        loadIds,
        truckLoadOptimizationRefIds,
        dateFrom,
        dateTo,
        bookingCategories,
        destinationLocCode,
      ),
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useShipmentByFiltersQuery = (
  bookingTypes: BookingTypes,
  vendorIds?: VendorId[],
  shipmentIds?: Array<string>,
  loadIds?: Array<string>,
  truckLoadOptimizationRefIds?: Array<string>,
  dateFrom?: string,
  dateTo?: string,
  bookingCategories?: Array<string>,
  destinationLocCode?: Array<string>,
  originLocCodes?: Array<string>,
  currentPage?: number,
  itemsPerPage?: number,
) => {
  return useQuery(
    [
      'shipments-by-filters',
      bookingTypes,
      vendorIds,
      shipmentIds,
      loadIds,
      truckLoadOptimizationRefIds,
      dateFrom,
      dateTo,
      bookingCategories,
      destinationLocCode,
      originLocCodes,
      currentPage,
      itemsPerPage,
    ],
    () =>
      getShipmentsByFilters(
        bookingTypes,
        undefined,
        undefined,
        vendorIds,
        shipmentIds,
        loadIds,
        truckLoadOptimizationRefIds,
        dateFrom,
        dateTo,
        bookingCategories,
        destinationLocCode,
        originLocCodes,
        currentPage,
        itemsPerPage,
      ),
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const usePurchaseOrderQuery = (
  onSuccess: ((data: any) => void) | undefined,
  onError: ((err: Error) => void) | undefined,
  enableUsePurchaseOrder: boolean,
  vendorIds?: Array<string>,
  purchaseOrders?: Array<string>,
  dateFrom?: string,
  dateTo?: string,
  shipmentIds?: Array<string>,
  loadIds?: Array<string>,
  departmentIds?: Array<string>,
  tmLocIds?: Array<string>,
  bookingCategories?: Array<string>,
  currentPage = 1,
  itemsPerPage = 10,
  destinationsLocCodes?: Array<string>,
  truckLoadOptimizationRefIds?: Array<string>,
) => {
  return useQuery(
    [
      'purchase_orders',
      enableUsePurchaseOrder,
      vendorIds,
      purchaseOrders,
      dateFrom,
      dateTo,
      shipmentIds,
      loadIds,
      departmentIds,
      tmLocIds,
      bookingCategories,
      currentPage,
      itemsPerPage,
      destinationsLocCodes,
      truckLoadOptimizationRefIds,
    ],
    () =>
      fetchPurchaseOrder(
        vendorIds,
        purchaseOrders,
        dateFrom,
        dateTo,
        shipmentIds,
        loadIds,
        departmentIds,
        tmLocIds,
        bookingCategories,
        truckLoadOptimizationRefIds,
        currentPage,
        itemsPerPage,
        destinationsLocCodes,
      ),
    {
      onSuccess,
      onError,
      select: (data: any) => {
        return {
          rawData: data,
          purchaseOrders: transformPurchaseOrders(data?.purchase_orders || []),
          paginationMetadata: transformPaginationMetadata(
            data?.pagination_metadata || {},
          ),
        }
      },
      enabled: enableUsePurchaseOrder,
      refetchOnWindowFocus: false,
    },
  )
}

export const useRetrieveDestinationsByPoAndDeptQuery = (
  po?: number | null,
  dept?: number | null,
) => {
  return useQuery(
    ['dc-locations', po, dept],
    () => fetchDestinationsForPoAndDeptEndpoint(po, dept),
    {
      select: (data: any) => {
        return transformPurchaseOrderDetails(data?.data)
      },
      refetchOnWindowFocus: false,
    },
  )
}

export const useVendorAttributesQuery = (
  vendorId: VendorId,
  onError: ((err: Error) => void) | undefined,
) => {
  return useQuery(
    ['vendor-attribute', vendorId],
    () => vendorAttributesEndpoint(vendorId),
    {
      select: (data: any) => {
        return {
          usePalletVolume: data?.data?.use_vendor_pallet_volume,
          businessHrsBasedPickup: data?.data?.business_hrs_based_pickup,
        }
      },
      refetchOnWindowFocus: false,
      onError,
    },
  )
}

export const useRetrieveIRDDateQuery = (
  po?: number | null,
  dept?: string | null,
) => {
  return useQuery(
    ['ird-dates', po, dept],
    () => fetchIRDDateEndpoint(po, dept),
    {
      select: (data: any) => {
        return transformIRD(data?.data)
      },
      refetchOnWindowFocus: false,
    },
  )
}

export const useDestinationByVendorIdsQuery = (
  bookingTypes: BookingTypes,
  origins?: string[],
  vendorIds?: number[],
) => {
  const destinationsForVendorIdsQuery = useQuery(
    ['destinations-vendorIds', vendorIds, bookingTypes, origins],
    () => getDestinationByVendorIdsEndpoint(vendorIds, bookingTypes, origins),
    {
      refetchOnWindowFocus: false, // default: true
    },
  )

  if (destinationsForVendorIdsQuery.isError) {
    logEvent(
      {
        message: JSON.stringify({
          endpoint: getDestinationByVendorIdsEndpoint,
          error: destinationsForVendorIdsQuery.error,
          message: 'Failed to fetch destinations for vendorIds',
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )
  }
  return destinationsForVendorIdsQuery
}

export const useShipmentsByTloAlerts = (
  vendorIds: number[],
  shipments: ShipmentRowInterface[],
) => {
  // Splitting the provided shipments into groups by tlo_ref_id
  const shipmentsByTLO: Record<string, ShipmentRowInterface[]> =
    shipments.reduce(
      (
        acc: Record<string, ShipmentRowInterface[]>,
        shipment: ShipmentRowInterface,
      ) => {
        if (!shipment.tloRefId) {
          return acc
        }

        if (acc[shipment.tloRefId]) {
          acc[shipment.tloRefId] = [...acc[shipment.tloRefId], shipment]
        } else {
          acc[shipment.tloRefId] = [shipment]
        }

        return acc
      },
      {},
    )

  // Extracting unique TLO ids
  const tloIds = Object.keys(shipmentsByTLO)

  const tloShipmentQueries =
    vendorIds?.length && tloIds?.length
      ? tloIds.map((id) => ({
          queryKey: [`shipmentsByTlo ${id}`],
          queryFn: () => getShipmentsNeedReviewByVendorTloRefId(vendorIds, id),
          select: (data: RawShipmentDetails[]): TloAlert | undefined => {
            // Comparison to see if there are more shipments in the API response
            if (data.length > shipmentsByTLO[id].length) {
              return {
                id: id,
                heading: 'TLO Shipments Incomplete',
                message: `There are ${
                  data.length - shipmentsByTLO[id].length
                } other shipments part of TLO ${id} that must be reviewed before they all can be successfully routed.`,
                type: 'alert',
                autoHideDuration: 20000,
              }
            }
          },
          refetchOnWindowFocus: false, // default: true
        }))
      : []

  return useQueries(tloShipmentQueries)
}

export const useReverseOriginLocationsQuery = (
  successHandler?: (data: ReverseOriginResponse[]) => void,
) => {
  return useQuery(
    'reverse-origin-locations',
    () => fetchReverseOriginLocations(),
    {
      onSuccess: successHandler,
      refetchOnWindowFocus: false, // default: true
      staleTime: Infinity,
    },
  )
}

export const useRetrieveReverseDestinationsQuery = () => {
  return useQuery('reverse-destinations', () => fetchReverseDestinations(), {
    select: (data: ReverseDestinationResponse[]) => {
      return data.map((destination): ReverseDestination => {
        return {
          tmLocId: destination.tm_loc_id,
          type: destination.type,
          locationId: destination.location_id,
          vendorId: destination.vendor_id,
          vendorName: destination.vendor_name,
          address: {
            addressLine: destination.address.address_line,
            addressLineTwo: destination.address.address_line_two,
            city: destination.address.city,
            state: destination.address.state,
            zipcode: destination.address.zipcode,
            countryCode: destination.address.country_code,
          },
          returnPolicyIds: destination.return_policy_ids,
        }
      })
    },
    refetchOnWindowFocus: false, // default: true
    staleTime: 43200000, // 12 hours
  })
}
